.AdSpace {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: var(--game-color);
    font-size: 2rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.AdSpaceDesktop {

}

.AdSpaceMobile {

}