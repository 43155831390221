.StatusNum {
    grid-row: 2;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.StatusNumDesktop {

}

.StatusNumMobile {

}

.WrongGuess {
    color: #aaaaaa;
}

.CorrectGuess {
    color: #40ee40;
}