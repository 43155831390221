.GameStatus {
    display: grid;
    grid-template-columns: 15fr 10fr 10fr;
    grid-template-rows: 1fr 3fr;

    border-top: 2px solid var(--border-color);
    height: 100%;
    width: 100%;

    margin-top: 0.5rem;
}

.GameStatusDesktop {

}

.GameStatusMobile {

}

.RemAttempts {
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    grid-column: 1;
    text-align: center;
    margin-top: 0.2rem;
}

.Tile1 {
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    grid-column: 2;
    text-align: center;
    margin-top: 0.2rem;
}

.Tile2 {
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    grid-column: 3;
    text-align: center;
    margin-top: 0.2rem;
}