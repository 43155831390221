.NewQuestionPreview {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.GameContainer {
    background-color: #ccc;
    width: 343px;
    height: 433px;
    box-shadow: 0rem 0rem 2rem #c2c2c2
}