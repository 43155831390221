.GameOverModal {
    margin: 0 auto;

    position: absolute;
    left: 50%;
    top: 50%;
    
    z-index: 10000;

    background-color: var(--game-color);
    border-radius: 0.5rem;
    border: 2px solid var(--border-color);

    display: grid;
    grid-template-rows: 10fr 20fr 40fr 15fr 80px;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;

    box-shadow: 0rem 0rem 2rem #3d3d3d;
    
}

.ShowModal {
    visibility: visible;
    animation: fadeIn 0.3s linear forwards;
}

.HideModal {
    visibility: hidden;
    animation: fadeOut 0.3s linear forwards;
}

.InitialHideModal {
    display: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -20%);
    }
    to { 
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
    }
}
  
@keyframes fadeOut {
    from { 
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
    }
    to {
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -20%);
    }
}

/* .HideSideDrawer {
    visibility: hidden;
    animation: fadeOut 0.3s linear forwards;
}

.ShowSideDrawer {
    visibility: visible;
    animation: fadeIn 0.3s linear forwards;
}

.InitialHideSideDrawer {
    display: none;
}

 */

.GameOverModalDesktop {
    top: 20.1rem;
    width: 24rem;
    min-height: 31rem;
}

.GameOverModalMobile {
    width: 85vw;
    max-height: 85vh;
}

.ExitX {
    display: grid;
    grid-row: 1;
    width: 100%;
    padding-top: 0.6rem;
    padding-right: 1.6rem;
    padding-left: 82%;
    cursor: pointer;
}

.Statistics {
    display: grid;
    grid-row: 2;
    grid-template-rows: 3fr 7fr;
    justify-content: center;
    text-align: center;
    width: 70%;
    height: 8rem;
    font-family: "Roboto", sans-serif;
}

.StatisticsTitle {
    height: 2rem;
    font-size: 1.3rem;
}

.StatDisplays {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%
}

.StatDisplay {
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 100%;
}

.StatNumber {
    font-size: 2rem;
    font-weight: 500;
    height: 2.5rem;
    grid-row: 1;
}

.StatName {
    font-size: 0.8rem;
    font-weight: 400;
    word-wrap: break-word;
    white-space: initial;
    grid-row: 2;
}

.GuessGraphContainer {
    display: grid;
    grid-template-rows: 15fr 80fr;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
    font-family: "Roboto", sans-serif;
}

.GuessGraphTitle {
    font-size: 1.3rem;
}

.GuessGraph {
    width: 80%;
    display: grid;
    grid-template-rows: 1.4rem 1.4rem 1.4rem 1.4rem 1.4rem 1.4rem;
    grid-gap: 0.25rem;
    align-items: center;
}

.Guess {
    display: grid;
    grid-template-columns: 1fr 10fr;
    height: 90%;
    font-size: 0.85rem;
    font-weight: 500;
}

.GuessNumber {
    display: flex;
    text-align: right;
    justify-content: right;
    align-items: center;
    padding-right: 0.5rem;
    padding-bottom: 0rem;
}

.GuessBar {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0rem;
}

.BtnTimerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 80%;
}

.ShareBtn {
    display: grid;
    grid-template-columns: 3fr 1fr;
    background-color: #15ad50;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 1rem;
    border-radius: 0.4rem;
    height: 3.5rem;
    width: 90%;
    padding-right: 0.3rem;
}


.StatsAdspace {
    width: 100%;
    max-height: 100%;
    display: grid;
    justify-content: center;
}