.QuestionItem {
    cursor: pointer;
}

.QuestionID {
    display: flex;
    align-items: center;
}

.ReleaseDate {
    font-size: 0.97rem;
}