.QuestionListContainer {
    width: 100%;
    min-height: 100%;
    font-family: "Roboto", sans-serif;
    grid-template-columns: 100%;
    grid-template-rows: min-content;
    display: grid;
    justify-content: center;
    margin-bottom: 5rem;
}

.QuestionList {
    margin: 0 auto;
    margin-top: 2rem;
    width: 80%;
    max-width: 50rem;
    box-shadow: 0rem 0rem 0.4rem #d1d1d1;
}

.NewQuestion {
    margin: 0 auto;
    margin-top: 1.6rem;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background-color: rgb(91, 91, 255);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
}

.NewQuestion:hover {
    background-color: rgb(113, 113, 255);
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}