:root {
    --main-bg-color: rgb(32, 37, 41);
    --game-color: rgb(17, 20, 18);
    --border-color: rgb(112, 112, 112);
    --dark-color: rgb(35, 40, 45);
    --green-color: rgb(21, 173, 80);
}

.Layout {
    width: 100vw;
    height: 100vh;
}