.NextBattle {
    font-weight: 800;
    font-stretch: extra-condensed;
    padding-right: 1rem;
    border-right: 1px solid var(--border-color);
}

.NextBattleTitle {
    font-size: 1.2rem;
    text-align: center;
}

.NextBattleTimer {
    font-size: 1.8rem;
    text-align: center;
}