.Sidebar {
    position: absolute;
    top: 0;
    left: -15rem;
    z-index: 1000;
    height: 100%;
    width: 15rem;
    background-color: var(--main-bg-color);
    transition: all 0.7s;
}

.ShowSidebar {
    left: 0;
}

.SidebarTitle {
    font-size: 1.8rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.CopyrightContainer {
    background-color: #232323;
}

.Copyright {
    font-size: 1.2rem;
}

.Modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1000;
    width: 100%;
    height: 100%;
    transition: all 0.7s;
}

.ShowModal {
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5)
}