.GamePage {
    background-color: var(--main-bg-color);
    color: white;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.GamePageDesktop {
    height: 100%;
}
