.AgeModalBackground {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(180, 180, 180, 0.6);
}

.AgeModal {
    margin: 0 auto;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -57.5%);
    z-index: 10000;

    background-color: var(--game-color);
    border-radius: 0.3rem;

    display: grid;
    grid-template-rows: 8fr 8fr 5fr 5fr;
    justify-items: center;
    align-items: center;

    box-shadow: 0rem 0rem 2rem #3d3d3d;
}

.AgeModalDesktop {
    top: 20.1rem;
    width: 24rem;
    height: 26rem;
}

.AgeModalMobile {
    width: 80vw;
    height: 70vh;
}

.AgeModalTitle {
    font-weight: 600;
    font-size: 1.7rem;
    padding: 0 1.5rem;
    text-align: center;
}

.AgeModalText {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0 1.5rem;
    margin-bottom: 3rem;
    text-align: center;
    word-spacing: -0.05rem;
}

.AgeModalButton {
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 0.25rem;
    width: 55%;
    height: 70%;
    cursor: pointer;
    border-radius: 0.4rem;
    text-align: center;
    font-weight: 700;
}

.AgeModalButtonGreen {
    background-color: var(--green-color);
    font-size: 1.7rem;
}

.AgeModalButtonBlack {
    background-color: var(--game-color);
    font-size: 1.5rem;
    margin-bottom: 1rem;
}