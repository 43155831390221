.Toolbar {
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 10000;
    /*box-shadow: 0px 6px 7px rgb(228, 228, 228);*/
    grid-column: 1 / 3;
    grid-row: 1;
    background-color: rgb(60, 60, 207);
    font-family: "Roboto", sans-serif;
}

.ToolbarLarge {
    height: 60px;
}

.ToolbarSmall {
    height: 50px;
}