.Privacy {
    background-color: white;
    margin: 2rem;
    font-family: "Roboto", sans-serif;
    padding: 3%;
}

.PrivacyDesktop {
    width: 70%;
    min-height: 30rem;
    box-shadow: 0rem 0rem 0.4rem #d1d1d1;
}

.PrivacyMobile {
    width: 100%;
    min-height: 30rem;
}

.BackButton {
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 0.5rem;
}

.Bold {
    font-weight: 500;
}

.Title {
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 1rem;
}

.SubTitle {
    font-weight: 500;
    font-size: 1.3rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
}

.IndentedDiv {
    padding-left: 3%;
}

.DoubleIndent {
    padding-left: 6%;
}

.BottomMarginDiv {
    margin-bottom: 0.6rem;
}

.AnchorTag {
    text-decoration: underline;
}
