.HeaderItem {
    padding: 0.4rem;
    cursor: pointer;
}

.HeaderItemDesktop {

}

.HeaderItemMobile {

}

.Title {
    font-size: 2rem;
    font-weight: 700;
    color: white;
    grid-column: 3;
    margin-bottom: 0.5rem;
}