.Header {
    width: 100%;
    height: 4rem;
    border-bottom: 2px solid var(--border-color);
    grid-row: 1;
    grid-column: 1;
}

.HeaderDesktop {

}

.HeaderMobile {

}