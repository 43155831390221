.GameContainer {
    background-color: var(--game-color);
    display: grid;
    grid-template-rows: 4rem auto 5rem 4rem;
    grid-template-columns: 1fr;
    justify-items: center;
    margin: 0 auto;
    overflow: hidden;
}

.GameContainerDesktop {
    margin-top: 2rem;
    width: 30rem;
    min-height: 40rem;
}

.GameContainerMobile {
    width: 100vw;
}

.HeightControl {
    height: 100vh;
    width: 0;
    position: absolute;
}