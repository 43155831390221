.HelpModal {
    margin: 0 auto;

    position: absolute;
    left: 50%;
    top: 50%;
    
    z-index: 10000;

    background-color: var(--game-color);
    
    

    display: grid;
    
    grid-template-columns: 1fr;
    justify-items: center;
    

    box-shadow: 0rem 0rem 2rem #3d3d3d;

    font-family: "Roboto", sans-serif;
}

.ShowModal {
    visibility: visible;
    animation: fadeIn 0.3s linear forwards;
}

.HideModal {
    visibility: hidden;
    animation: fadeOut 0.3s linear forwards;
}

.InitialHideModal {
    display: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, 0%);
    }
    to { 
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
    }
}
  
@keyframes fadeOut {
    from { 
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
    }
    to {
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, 0%);
    }
}

.GreenText {
    color: rgb(10, 214, 20);
}

.GreyText {
    color: rgb(160, 160, 160);
}

.BoldText {
    font-weight: 500;
}

.ExitX {
    display: grid;
    grid-row: 1;
    width: 100%;
    align-items: center;
    padding-top: 0.6rem;
    padding-right: 1.6rem;
    padding-left: 85%;
    cursor: pointer;
}

.HelpModalDesktop {
    top: 20.1rem;
    width: 24rem;
    min-height: 27.5rem;
    transform: translate(-50%, -43.5%);
    grid-template-rows: 8% 8% auto;
    padding: 0.6rem 0rem 1.5rem 0rem;
    border-radius: 0.5rem;
    border: 2px solid var(--border-color);
}

.HelpModalMobile {
    width: 100vw;
    max-height: 100%;
    transform: translate(-50%, -50%);
    grid-template-rows: 10% 8% fit-content(30%);
}

.HelpModalTitle {
    font-weight: 400;
    font-size: 1.5rem;
    padding: 0 1.5rem;
    text-align: center;
}

.InstructionList {
    display: grid;
    grid-template-rows: auto;
    justify-items: center;

    width: 100%;

    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.Instruction {
    width: 90%;
    padding: 0.9rem 0.5rem;
    margin: 0 0.8rem;
    box-sizing: border-box;
}

.NewChallengeEveryDay {
    padding-top: 1.6rem;
    border-top: 1px solid rgb(66, 66, 66);
}
