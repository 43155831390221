.SidebarInputContainer {
    display: grid;
    grid-template-columns: 10fr 15fr;
    height: 3rem;
    align-items: center;
}

.SidebarInputContainerDouble {
    display: grid;
    grid-template-columns: 20fr 15fr 15fr;
    height: 3rem;
    align-items: center;
}

.SidebarInputFieldDouble:hover {
    background-color: #f9f9f9;
}

.SidebarInputName {
    color: #333;
}

.SidebarInputFieldSingle {
    height: 80%;
    width: 100%;
    border-radius: 0.4rem;
    border: 1px solid #ccc;
    grid-column: 2;
    margin: 0 auto;
    padding: 0 0.5rem;
    outline: none;
}

.SidebarInputFieldSingle:hover {
    background-color: #f9f9f9;
}

.DoubleInputWrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    align-items: center;
}

.FieldName {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #444;
}

.SidebarInputFieldDouble {
    height: 70%;
    width: 90%;
    border-radius: 0.4rem;
    border: 1px solid #ccc;
    margin: 0 auto;
    padding: 0 0.5rem;
    outline: none;
    text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}