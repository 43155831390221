.GameScreen {
    margin-top: 1.3rem;
    
    background-color: var(--game-color);
    grid-row: 2;
    grid-column: 1;
    box-sizing: border-box;
}

.GameScreenDesktop {
    width: calc(100% - 2rem);
    height: calc(100% - 20.8px);
}

.GameScreenMobile {
    width: calc(100% - 2rem);
    height: calc(100% - 24.8px);
}

