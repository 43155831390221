.NewQuestionSidebar {
    grid-column: 1;
    background-color: #fafafa;
    border-right: 1px solid #ccc;
    width: 400px;
    height: 100%;

    display: grid;
    grid-auto-rows: 3rem;
    padding: 20px 20px;
    align-items: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    grid-gap: 0rem;
}

.SidebarInputContainer {
    display: grid;
    grid-template-columns: 10fr 15fr;
    height: 3rem;
    align-items: center;
}

.SidebarInputFieldSingle {
    height: 80%;
    width: 100%;
    border-radius: 0.4rem;
    border: 1px solid #ccc;
    grid-column: 2;
    margin: 0 auto;
    padding: 0 0.5rem;
    outline: none;
}

.SidebarInputFieldSingle:hover {
    background-color: #f9f9f9;
}

.SidebarInputName {
    color: #333;
}

.SaveQuestionButton {
    background-color: rgb(74, 74, 231);
    color: white;
    border-radius: 0.6rem;
    height: 3rem;
    margin-top: 3rem;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    margin-bottom: 1rem;
}

.SaveQuestionButton:hover {
    background-color: rgb(88, 88, 230);
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.DeleteQuestionButton {
    background-color: rgb(233, 23, 23);
    color: white;
    border-radius: 0.6rem;
    height: 3rem;
    margin-top: 3rem;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.DeleteQuestionButton:hover {
    background-color: rgb(235, 42, 42);
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

::-ms-browse {
    cursor: pointer;
}

::-webkit-file-upload-button {
    cursor: pointer;
}