.ToolbarBtn {
    width: 125px;
    padding: 0;
    font-size: 1.1rem;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 4px 0;
    font-weight: 400;
    color: #f0f2f5;
}

.ToolbarBtnLarge {
    height: 60px;
}

.ToolbarBtnSmall {
    height: 50px;
}

.ToolbarBtnWhite {
    color: #f0f2f5;
}

.ToolbarBtnBlack {
    color: #f0f2f5;
}

.ToolbarBtnActive {
    border-bottom: 3px solid rgb(255, 255, 255);
    margin-bottom: -3px;
}

.ToolbarBtn:hover {
    cursor: pointer;
    border-bottom: 3px solid rgb(255, 255, 255);
    margin-bottom: -3px;
}
